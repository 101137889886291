import '../sass/importer.sass';

import { Suspense, lazy, useContext } from 'react';
import {
  Route,
  createRoutesFromElements,
  createBrowserRouter,
  RouterProvider,
  Outlet,
  useLocation,
} from 'react-router-dom';
import { Offline } from 'react-detect-offline';
import { useDispatch, useSelector } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import * as Sentry from '@sentry/react';
import { useFeatureIsOn, GrowthBookProvider } from '@growthbook/growthbook-react';
import '@mantine/core/styles.layer.css';
import { WalletProvider } from '@advisa/wallet';

import { useSetupGrowthbook } from '_js/utils/3rdparty/growthBook';

import HistoryWrapper from '_js/utils/HistoryWrapper';
import useEffectOnce from '_js/hooks/useEffectOnce';

import GlobalStyles from '_js/styles/GlobalStyles';
import { trackPageView } from '_js/utils/external/Tracking';
import { shouldRedirectToCreditor } from '_js/store/actions/should-redirect-to-creditor';
import { getTheme, getBrandAndMarket, getBrand } from '_js/brands';
import ErrorBoundaryFallback from '_js/components/atoms/ErrorBoundaryFallback';
import OfflineMessage from '_js/components/atoms/OfflineMessage';

import Config from '_js/utils/config';
import { NEW_STEPPER_FEATURE, INSURANCE_FEATURE } from '_js/localizations/config/features';
import localeData from '_js/localizations/locale-data';

import Loading from './components/atoms/Loading';
import creditCardLoader from './pages/creditCard/loader';
import CreditCardError from './pages/creditCard/Error';
import Stepper from './components/ui/Stepper';
import BreadcrumbList from './components/molecules/BreadcrumbList';
import Routes from './constants/Routes';
import { initAuth } from './store/actions/auth';
import Gjeldsregisteret from './components/gjeldsregisteret';
import { LanguageProvider, LanguageContext } from './providers/LanguageProvider';

const CreditCard = lazy(() => import('./pages/creditCard'));
const Header = lazy(() => import('./containers/Header'));
const ApplicationFinder = lazy(() => import('./containers/ApplicationFinder'));
const ApplicationManager = lazy(() => import('./containers/ApplicationManager'));
const AuthManager = lazy(() => import('./containers/AuthManager'));
const Autogiro = lazy(() => import('./components/autogiroLink'));
const InternalLoginSuccessful = lazy(() => import('./pages/SuccessfulLogin'));
const MultipleApplicationsPage = lazy(() =>
  import('./pages/multipleApplications/MultipleApplicationsOverview'),
);

const currentTheme = getTheme();

let instantiated = false;

function Layout() {
  const { pathname } = useLocation();
  const isNewStepperEnabled = useFeatureIsOn(NEW_STEPPER_FEATURE);
  const routeRegex = new RegExp(Routes.NoSelfService.exactUrlRegex);
  const excludeStepper = pathname.match(routeRegex);
  const isTestHeaderEnabled = useFeatureIsOn('test-header');
  if (isTestHeaderEnabled) {
    console.log('Test header is enabled');
  } else {
    console.log('Test header is disabled');
  }
  let ConditionalStepper = () => null;
  if (!excludeStepper) {
    ConditionalStepper = isNewStepperEnabled ? Stepper : BreadcrumbList;
  }
  return (
    <>
      <Header />
      <main
        className="
          App
          c-app
          "
      >
        <Offline>
          <OfflineMessage />
        </Offline>
        <AuthManager>
          <ConditionalStepper />
          <Outlet />
        </AuthManager>
      </main>
    </>
  );
}

const DefineRoutes = () => {
  const isInsuranceFeatureEnabled = useFeatureIsOn(INSURANCE_FEATURE);
  const brandAndMarket = getBrandAndMarket();
  const isShowingMultipleApplications = useSelector(
    (state) => state.application.isShowingMultipleApplications,
  );
  const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);

  const routes = createRoutesFromElements(
    <>
      <Route path="/" element={<Layout />}>
        {isInsuranceFeatureEnabled && <Route path="autogiro" element={<Autogiro />} />}
        {isShowingMultipleApplications && (
          <Route path="applications" element={<MultipleApplicationsPage />} />
        )}

        <Route
          element={<CreditCard />}
          path="credit-card"
          loader={creditCardLoader}
          errorElement={<CreditCardError />}
        />
        <Route path="internal-login-successful" element={<InternalLoginSuccessful />} />
        <Route path="application/:applicationId/*" element={<ApplicationManager />} />
        <Route path="*" element={<ApplicationFinder />} />
        <Route index element={<ApplicationFinder />} />
      </Route>
      {(brandAndMarket === 'Sambla_no' || brandAndMarket === 'Digifinans_no') && (
        <Route
          path="gjeldsregisteret"
          element={
            <>
              <Header />
              <main className="App c-app">
                <Offline>
                  <OfflineMessage />
                </Offline>
                <Gjeldsregisteret completeStep={() => null} />
              </main>
            </>
          }
        />
      )}
    </>,
  );
  const router = sentryCreateBrowserRouter(routes);

  return <RouterProvider router={router} />;
};

const App = () => {
  const { language } = useContext(LanguageContext);

  const dispatch = useDispatch();
  const onPageChange = () => {
    window.scrollTo(0, 0);
    trackPageView();
  };

  useEffectOnce(() => {
    dispatch(initAuth());
  });

  onPageChange(window.location);
  const performUrlAction = () => {
    const pathnameMatch = window.location.pathname.match(/^\/\w+/);
    if (!pathnameMatch) return;
    const pathname = pathnameMatch[0];

    switch (pathname) {
      case '/sign': {
        dispatch(shouldRedirectToCreditor(true));
        break;
      }
      default:
    }
  };

  useEffectOnce(performUrlAction);

  const growthbook = useSetupGrowthbook();

  if (!instantiated) {
    HistoryWrapper.init();
    Config.instantiate(localeData);
    instantiated = true;
  }

  Sentry.setTag('language', language);

  return (
    <ThemeProvider theme={currentTheme}>
      <WalletProvider brand={getBrand()}>
        <LanguageProvider>
          <Sentry.ErrorBoundary fallback={<ErrorBoundaryFallback />}>
            <Suspense fallback={<Loading />}>
              <GrowthBookProvider growthbook={growthbook}>
                <DefineRoutes />
              </GrowthBookProvider>
            </Suspense>
          </Sentry.ErrorBoundary>
          <GlobalStyles />
        </LanguageProvider>
      </WalletProvider>
    </ThemeProvider>
  );
};

export default Sentry.withProfiler(App);
