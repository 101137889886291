import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from '@redux-devtools/extension';
import thunk from 'redux-thunk';
import shapeshifter from 'redux-shapeshifter-middleware';
import * as Sentry from '@sentry/react';
import googleAuth from '_js/store/middleware/googleAuth';
import { getBrandAPIRoot } from '_js/brands';
import { actionTransformer, stateTransformer } from '_js/utils/3rdparty/sentry';
import reducers from './reducers';

const shapeshifterMiddleware = shapeshifter({
  base: getBrandAPIRoot(),
  auth: {
    headers: {
      Authorization: 'Bearer #token',
    },
  },
  useOnlyAxiosStatusResponse: true,
  useETags: true,
  emitRequestType: true,
});

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  actionTransformer,
  stateTransformer,
});

export const configureStore = ({ reducer, initialState, middlewares = [] }) =>
  initialState == null
    ? createStore(
        reducer,
        composeWithDevTools(applyMiddleware(...middlewares), sentryReduxEnhancer),
      )
    : createStore(
        reducer,
        initialState,
        composeWithDevTools(applyMiddleware(...middlewares), sentryReduxEnhancer),
      );

export const middlewares = [shapeshifterMiddleware, thunk, googleAuth];

export default configureStore({
  reducer: reducers,
  middlewares,
});
