// internal
import { API } from '_js/store/constants';
import { isDevelopment } from '_js/utils/environment';

export const FETCH_APPLICATION = 'API/SYSTEM/FETCH_APPLICATION';
export const FETCH_APPLICATION_SUCCESS = 'API/SYSTEM/FETCH_APPLICATION_SUCCESS';
export const FETCH_APPLICATION_FAILED = 'API/SYSTEM/FETCH_APPLICATION_FAILED';

export const FETCH_APPLICATION_ACCOUNTS = 'API/SYSTEM/FETCH_APPLICATION_ACCOUNTS';
export const FETCH_APPLICATION_ACCOUNTS_SUCCESS = 'API/SYSTEM/FETCH_APPLICATION_ACCOUNTS_SUCCESS';
export const FETCH_APPLICATION_ACCOUNTS_FAILED = 'API/SYSTEM/FETCH_APPLICATION_ACCOUNTS_FAILED';
export const FETCH_APPLICATION_ACCOUNTS_UNMODIFIED =
  'API/SYSTEM/FETCH_APPLICATION_ACCOUNTS_UNMODIFIED';

const success = (type, payload) => ({
  type,
  payload,
});

const failure = (type, error) => ({
  type,
  message: 'Failed to fetch application.',
  error,
});

export const fetchApplication = (id) => ({
  type: API,
  types: [FETCH_APPLICATION, FETCH_APPLICATION_SUCCESS, FETCH_APPLICATION_FAILED],
  payload: () => ({
    url: `/applications/${id}`,
    success,
    failure,
    auth: true,
  }),
  axios: {
    withCredentials: !isDevelopment(),
  },
});

function unmodified() {
  return {
    type: FETCH_APPLICATION_ACCOUNTS_UNMODIFIED,
  };
}

export const getApplicationAccounts = (id) => ({
  type: API,
  types: [
    FETCH_APPLICATION_ACCOUNTS,
    FETCH_APPLICATION_ACCOUNTS_SUCCESS,
    FETCH_APPLICATION_ACCOUNTS_FAILED,
  ],
  payload: () => ({
    url: `/applications/${id}/bankAccounts`,
    success,
    failure,
    auth: true,
    ETagCallback: ({ dispatch }) => {
      dispatch(unmodified());
    },
  }),
  axios: {
    withCredentials: !isDevelopment(),
  },
});
