// internal
import { API } from '_js/store/constants';
import { isDevelopment } from '_js/utils/environment';

export const FETCH_BIDDING = 'API/SYSTEM/FETCH_BIDDING';
export const FETCH_BIDDING_SUCCESS = 'API/SYSTEM/FETCH_BIDDING_SUCCESS';
export const FETCH_BIDDING_FAILED = 'API/SYSTEM/FETCH_BIDDING_FAILED';

const success = (type, payload) => ({
  type,
  payload,
});

const failure = (type, error) => ({
  type,
  message: 'Failed to fetch bidding for user.',
  error,
});

export const fetchBidding = (id) => ({
  type: API,
  types: [FETCH_BIDDING, FETCH_BIDDING_SUCCESS, FETCH_BIDDING_FAILED],
  payload: () => ({
    url: `/applications/${id}/bidding`,
    success,
    failure,
    auth: true,
  }),
  axios: {
    withCredentials: !isDevelopment(),
  },
});
