import { fetchApplication } from '_js/store/actions/api/application/fetch';
import { fetchBidding } from '_js/store/actions/api/bidding/fetch';
import { fetchCreditors } from '_js/store/actions/api/creditors/fetch';
import { getInsuranceEligibility, getPpiInfo } from '_js/store/actions/api/ppi/fetch';

export const APPLICATION_GET_INITIAL_STATE = 'APPLICATION_GET_INITIAL_STATE';
export const APPLICATION_GET_INITIAL_STATE_FAILED = 'APPLICATION_GET_INITIAL_STATE_FAILED';

export const getInitialState = (applicationId, isInsuranceFeatureEnabled) => (dispatch) => {
  const promises = [
    dispatch(fetchBidding(applicationId)),
    dispatch(fetchApplication(applicationId)),
    dispatch(fetchCreditors()),
  ];

  return Promise.all(promises)
    .catch((error) => {
      dispatch({ type: APPLICATION_GET_INITIAL_STATE_FAILED, error });
      return Promise.reject(error);
    })
    .then(([biddingResponse]) => {
      if (isInsuranceFeatureEnabled) {
        dispatch(
          getPpiInfo({
            applicationId,
          }),
        );
        dispatch(getInsuranceEligibility({ applicationId }));
      }
      if (!biddingResponse || !biddingResponse.data) {
        return Promise.resolve();
      }

      const { data } = biddingResponse;

      return Promise.resolve(data);
    });
};
