// internal
import { API } from '_js/store/constants';
import { getBrandAPIRoot } from '_js/brands';
import { isDevelopment } from '_js/utils/environment';

export const SUBMIT_MORTGAGE_INTEREST = 'API/SYSTEM/SUBMIT_MORTGAGE_INTEREST';
export const SUBMIT_MORTGAGE_INTEREST_SUCCESS = 'API/SYSTEM/SUBMIT_MORTGAGE_INTEREST_SUCCESS';
export const SUBMIT_MORTGAGE_INTEREST_FAILED = 'API/SYSTEM/SUBMIT_MORTGAGE_INTEREST_FAILED';

const success = (type, payload) => ({
  type,
  payload,
});

const failure = (type, error) => ({
  type,
  error,
});

export const submitMortgageInterest = (applicationId) => ({
  type: API,
  types: [
    SUBMIT_MORTGAGE_INTEREST,
    SUBMIT_MORTGAGE_INTEREST_SUCCESS,
    SUBMIT_MORTGAGE_INTEREST_FAILED,
  ],
  method: 'post',
  payload: () => ({
    url: `/applications/${applicationId}/mortgageInterest`,
    success,
    failure,
    throwOnError: true,
    auth: true,
  }),
  axios: {
    baseURL: getBrandAPIRoot(),
    withCredentials: !isDevelopment(),
  },
});
