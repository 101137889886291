import {
  Se60PlusBankenLogo,
  SeAvidaFinansLogo,
  SeBalanziaLogo,
  SeBankNorwegianLogo,
  SeBankyLogo,
  SeBigBankLogo,
  SeBluestepLogo,
  SeBrixoLogo,
  SeBroccLogo,
  SeByaxLogo,
  SeCashbuddyLogo,
  SeCollectorLogo,
  SeCredentoLogo,
  SeCredigoLogo,
  SeEquiloLogo,
  SeFacitLogo,
  SeFellowFinanceLogo,
  SeFerratumLogo,
  SeForexBankLogo,
  SeFriiaLogo,
  SeGoodcashLogo,
  SeHypoteketLogo,
  SeIcaBankenLogo,
  SeIkanoBankLogo,
  SeInstabankLogo,
  SeKonsumentkreditLogo,
  SeLanSparBankLogo,
  SeLeaBankLogo,
  SeLoanStepLogo,
  SeLunarLogo,
  SeMarginalenBankLogo,
  SeMedmeraBankLogo,
  SeMoankLogo,
  SeMoneyGoLogo,
  SeMorrowBankLogo,
  SeNordaxBankLogo,
  SeNordeaLogo,
  SeNordnetBankLogo,
  SeNorthmillBankLogo,
  SeNstartLogo,
  SePrimalCapitalLogo,
  SeRebillaLogo,
  SeRememberLogo,
  SeResursBankLogo,
  SeRisicumLogo,
  SeSaldoBankLogo,
  SeSantanderConsumerBankLogo,
  SeSantanderLogo,
  SeSebLogo,
  SeSevendayBankLogo,
  SeSpendwiseLogo,
  SeSvealånetLogo,
  SeSwedbankLogo,
  SeTfbankLogo,
  SeThornLogo,
  SeTryggKreditLogo,
  SeWasaKreditLogo,
  SeWastgotaFinansLogo,
} from '@advisa/wallet/svg';

const LOGOS = {
  '60plusbanken': <Se60PlusBankenLogo />,
  'avida-finans-lan': <SeAvidaFinansLogo />,
  'avida-finans': <SeAvidaFinansLogo />,
  balanzia: <SeBalanziaLogo />,
  'bank-norwegian': <SeBankNorwegianLogo />,
  banky: <SeBankyLogo />,
  bigbank: <SeBigBankLogo />,
  brixo: <SeBrixoLogo />,
  'brixo-privateloan': <SeBrixoLogo />,
  brocc: <SeBroccLogo />,
  byax: <SeByaxLogo />,
  cashbuddy: <SeCashbuddyLogo />,
  collector: <SeCollectorLogo />,
  'collector-lilla-lanet': <SeCollectorLogo />,
  credento: <SeCredentoLogo />,
  credigo: <SeCredigoLogo />,
  equilo: <SeEquiloLogo />,
  facit: <SeFacitLogo />,
  'fellow-finance': <SeFellowFinanceLogo />,
  ferratum: <SeFerratumLogo />,
  'forex-bank': <SeForexBankLogo />,
  friia: <SeFriiaLogo />,
  'ge-money-bank': <SeSantanderConsumerBankLogo />,
  goodcash: <SeGoodcashLogo />,
  hypoteket: <SeHypoteketLogo />,
  'ica-banken': <SeIcaBankenLogo />,
  'ikano-bank': <SeIkanoBankLogo />,
  ikano: <SeIkanoBankLogo />,
  instabank: <SeInstabankLogo />,
  konsumentkredit: <SeKonsumentkreditLogo />,
  'lea-bank': <SeLeaBankLogo />,
  loanstep: <SeLoanStepLogo />,
  lunar: <SeLunarLogo />,
  'marginalen-bank': <SeMarginalenBankLogo />,
  'medmera-bank': <SeMedmeraBankLogo />,
  moank: <SeMoankLogo />,
  moneypal: <SeMoneyGoLogo />,
  'morrow-bank': <SeMorrowBankLogo />,
  nordax: <SeNordaxBankLogo />,
  nordea: <SeNordeaLogo />,
  'nordnet-bank': <SeNordnetBankLogo />,
  'northmill-kontokredit': <SeNorthmillBankLogo />,
  'northmill-pafyllning': <SeNorthmillBankLogo />,
  nstart: <SeNstartLogo />,
  'primal-capital': <SePrimalCapitalLogo />,
  rebilla: <SeRebillaLogo />,
  remember: <SeRememberLogo />,
  'resurs-bank': <SeResursBankLogo />,
  risicum: <SeRisicumLogo />,
  saldo: <SeSaldoBankLogo />,
  'santander-consumer-bank': <SeSantanderLogo />,
  seb: <SeSebLogo />,
  'sevenday-finans': <SeSevendayBankLogo />,
  sparlan: <SeBroccLogo />,
  'spring-finance': <SeBluestepLogo />,
  'sveadirekt-konsument': <SeSvealånetLogo />,
  'sveadirekt-privat': <SeSvealånetLogo />,
  swedbank: <SeSwedbankLogo />,
  spendwise: <SeSpendwiseLogo />,
  tfbank: <SeTfbankLogo />,
  thorn: <SeThornLogo />,
  tryggkredit: <SeTryggKreditLogo />,
  villabanken: <SeLanSparBankLogo />,
  'wasa-kredit': <SeWasaKreditLogo />,
  'wastgota-finans': <SeWastgotaFinansLogo />,
};

export default LOGOS;
