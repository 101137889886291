import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { CheckIcon } from '@heroicons/react/24/outline';

const bulletSize = '1.4rem';

const Number = styled.p`
  font-size: 0.875rem;
`;

const Bullet = styled.div`
  border: 0.1rem solid var(--breadcrumb-line-color);
  border-radius: 100%;
  width: ${bulletSize};
  height: ${bulletSize};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0.5em;
  background-color: var(--breadcrumb-bg-color);
  z-index: 2;
`;

const ListItem = styled.li`
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-transform: uppercase;
  justify-content: end;
  font-weight: bold;
  font-size: ${(props) => (props['aria-current'] ? '1rem' : '0.75rem')};
  color: ${(props) =>
    props['aria-current'] ? 'var(--breadcrumb-bullet-font-color)' : 'var(--breadcrumb-line-color)'};
  position: relative;
  align-content: stretch;
  ${(props) =>
    props['aria-current'] &&
    css`
      ${Bullet} {
        color: var(--breadcrumb-bg-color);
        background-color: var(--breadcrumb-bg-bullet);
        border-color: var(--breadcrumb-border-color);
      }
      &::after {
        display: block;
        position: absolute;
        bottom: 0.65rem;
        right: 50%;
        width: 100rem;
        border-bottom: 0.1rem solid var(--breadcrumb-line-color);
        content: '';
        z-index: 1;
      }
    `};
`;

const Step = ({ done, active, number, children }) => (
  <ListItem aria-current={active && 'step'}>
    {children}
    <Bullet>
      {done ? (
        <CheckIcon
          width="16px"
          height="16px"
          style={{
            color: 'var(--breadcrumb-line-color)',
          }}
        />
      ) : (
        <Number>{number}</Number>
      )}
    </Bullet>
  </ListItem>
);

Step.propTypes = {
  done: PropTypes.bool,
  active: PropTypes.bool,
  number: PropTypes.number,
  children: PropTypes.node,
};

Step.defaultProps = {
  done: false,
  active: false,
  number: 1,
  children: null,
};

export default Step;
